/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';

import SelectOption from '@heureca/shared/models/SelectOption.model';
import IFormValues from '@heureca/shared/models/IFormValues.model';

import * as styles from './select.module.scss';

interface Props {
  selectClassName?: string;
  labelClassName?: string;
  labelText?: string;
  options: SelectOption[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  name?: Path<IFormValues>;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  register?: UseFormRegister<IFormValues>;
  required: boolean;
  error?: FieldError;
}

const Select = (props: Props) => {
  const {
    selectClassName,
    labelClassName,
    labelText,
    options,
    onChange,
    name,
    value,
    placeholder,
    disabled,
    register,
    required,
    error,
  } = props;

  return (
    <div className={styles.inputWrapper} style={{ width: '100%' }}>
      {labelText && (
        <label className={classNames(labelClassName, styles.label)} htmlFor={value}>
          {labelText}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div className={selectClassName}>
        <select
          {...register(name, { required })}
          defaultValue={value}
          className={classNames(styles.input, { [styles.error]: error })}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="">Choisissez une option</option>
          {options.map((o) => (
            <option key={o.value} value={o.value}>{o.label}</option>
          ))}
        </select>
        <p className={classNames({ [styles.errorMessage]: !error })}>Ce champ est requis</p>
      </div>
    </div>
  );
};

export default Select;
