import { useEffect, useState } from 'react';

const useFetch = <T>(request: () => Promise<T>, defaultValue?: T) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<T>(defaultValue);
  const [error, setError] = useState(null);

  const fetch = async () => {
    try {
      setLoading(true);
      const result = await request();
      setData(result);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
      // TODO NOTIFICATION
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    isLoading,
    data,
    setData,
    error,
    refresh: fetch,
  };
};

export default useFetch;
