/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';

import Input from '@heureca/shared/components/Input';
import Select from '@heureca/shared/components/Select/Select';
import IFormValues from '@heureca/shared/models/IFormValues.model';
import Button from '@heureca/shared/components/Button';
import PreRegistration from '@heureca/signUp/models/PreRegistration.model';
import useFetch from '@heureca/shared/hooks/useFetch.hook';
import selectMappers from '@heureca/shared/mappers/select.mappers';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import validatorsUtils from '@heureca/shared/utils/validators.utils';
import Steps from '@heureca/shared/components/Steps';
import stringUtils from '@heureca/shared/utils/string.utils';
import countryConstants from '@heureca/signUp/constants/country.constants';
import companiesService from '@heureca/signUp/services/companies.service';
import companyTypesService from '@heureca/signUp/services/company-types.service';
import useAlert from '@heureca/shared/hooks/useAlert';
import useDebounce from '@heureca/shared/hooks/useDebounce';
import usePrevious from '@heureca/shared/hooks/usePrevious.hook';
import routeConstants from '@heureca/signUp/constants/route.constants';
import useSignUp from '@heureca/signUp/hooks/useSignUp.hook';
import Layout from '@heureca/shared/components/Layout';
import analyticsUtils from '@heureca/shared/utils/analytics.utils';

import InputWebsite from '../InputWebsite';
import SignUpSectionCard from '../SignUpSectionCard/SignUpSectionCard';

import * as styles from './shop.module.scss';

const options = [
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: 'Néerlandais',
    value: 'nl',
  },
  {
    label: 'Anglais',
    value: 'en',
  },
  {
    label: 'Espagnol',
    value: 'sp',
  },
];

const countryOptions = [
  {
    label: countryConstants.BELGIUM,
    value: countryConstants.BELGIUM,
  },
  {
    label: countryConstants.FRANCE,
    value: countryConstants.FRANCE,
  },
  {
    label: countryConstants.LUX,
    value: countryConstants.LUX,
  },
  {
    label: countryConstants.NEDERLANDS,
    value: countryConstants.NEDERLANDS,
  },
  {
    label: countryConstants.SPAIN,
    value: countryConstants.SPAIN,
  },
];

const Shop = () => {
  const { updateSignUp, signUp } = useSignUp(() => navigate(routeConstants.ADDRESS));
  const { value: val } = signUp.shop;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<IFormValues>({ mode: 'onBlur', reValidateMode: 'onChange' });
  const { translate } = useTranslations();
  const { addAlert } = useAlert();

  const [websiteErrorMessage, setWebsiteErrorMessage] = useState('');
  const [isValid, setIsValid] = useState(true);

  const previousIsValid = usePrevious(isValid);

  useEffect(() => {
    if (previousIsValid !== isValid) {
      trigger('shop.routeName');
    }
  }, [isValid]);

  const getIsValid = () => isValid || !websiteErrorMessage;

  const onNext = async ({ shop, address }: Partial<PreRegistration>) => {
    analyticsUtils.addEvent({
      event: 'Shop step completed',
      payload: {
        name: shop.name,
        tvaNumber: shop.vat,
      },
    });

    updateSignUp({
      shop: {
        valid: true,
        value: {
          ...shop,
          routeName: stringUtils.getSanitizedLink(watch('shop.routeName')),
        },
      },
      address: {
        valid: false,
        value: {
          ...address,
          country: address.country,
        },
      },
    });
  };

  const onChange = async (value: string) => {
    if (!value) {
      return;
    }

    try {
      const exists = await companiesService.exists(value);
      setIsValid(!exists);

      if (exists) {
        setWebsiteErrorMessage(translate('signUp.websiteExists'));
      } else {
        setWebsiteErrorMessage('');
      }
    } catch (error) {
      addAlert('error', error.message);
    }
  };

  const setWebsiteValue = (value: string) => {
    setValue(
      'shop.routeName',
      stringUtils.getSanitizedLink(value),
    );
  };

  const debouncedOnChange = useDebounce(onChange);

  const { data: companyTypes } = useFetch(companyTypesService.fetchCompanyTypes, []);
  const [mask, setMask] = useState('BE9999.999.999');

  const companyTypesAsOptions = useMemo(() => companyTypes
    .map((c) => selectMappers.mapCompanyTypeToSelect({
      id: c.id,
      keyName: translate(`companyTypes.${c.keyName}`),
    })), [companyTypes]);

  const onCountryChange = (country: string) => {
    if (country === countryConstants.BELGIUM) {
      setMask('aa9999.999.999');
    } else {
      setValue('shop.vat', '');
      setMask(null);
    }
  };

  return (
    <Layout pageTitle={translate('signUp.title')} isSignUp>
      <SignUpSectionCard
        name="personnal-data"
        title={translate('signUp.steps.shop')}
      >
        <Steps currentStep={1} />
        <form className={styles.form} onSubmit={handleSubmit(onNext)}>
          <Select
            options={countryOptions}
            value="Belgique"
            name="address.country"
            labelText={translate('signUp.country')}
            selectClassName={styles.input}
            register={register}
            required
            error={errors.address?.country}
            onChange={(e) => onCountryChange(e.target.value)}
          />
          <Select
            options={options}
            value="fr"
            name="shop.adminCulture"
            labelText={translate('signUp.chooseLanguage')}
            selectClassName={styles.input}
            register={register}
            required
            error={errors.shop?.adminCulture}
          />
          <Input
            label={translate('signUp.name')}
            name="shop.name"
            wrapperClassName={styles.input}
            register={register}
            required
            value={val?.name}
            onChange={(e) => {
              setWebsiteValue(e.target.value);
              debouncedOnChange(stringUtils.getSanitizedLink(e.target.value));
            }}
            error={errors.shop?.name}
            customErrorMessage={translate('signUp.nameRequired')}
          />
          <Input
            label={translate('signUp.vat')}
            name="shop.vat"
            wrapperClassName={styles.input}
            register={register}
            validate={(value) => validatorsUtils.isVATNumberValid(value, watch('address.country'))}
            required
            value={val?.vat}
            error={errors.shop?.vat}
            customErrorMessage={translate('signUp.vatError')}
            mask={mask}
          />
          <InputWebsite
            label={translate('signUp.website')}
            required
            name="shop.routeName"
            value={val?.routeName}
            register={register}
            error={errors.shop?.routeName}
            validate={getIsValid}
            customErrorMessage={websiteErrorMessage || translate('signUp.websiteRequired')}
            onChange={(e) => {
              setWebsiteValue(e.target.value);
              debouncedOnChange(e.target.value);
            }}
          />
          <Select
            options={companyTypesAsOptions}
            name="shop.companyTypeId"
            labelText={translate('signUp.shopType')}
            value={val?.companyTypeId}
            selectClassName={styles.input}
            register={register}
            required
            error={errors.shop?.companyTypeId}
          />
          <div className={styles.buttonWrapper}>
            <Button
              label={translate('signUp.next')}
              type="submit"
              classNames={styles.submitButton}
            />
          </div>
        </form>
      </SignUpSectionCard>
    </Layout>
  );
};

export default Shop;
