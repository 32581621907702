import ApiError from '@heureca/shared/models/ApiError.model';
import httpService from '@heureca/shared/services/http.service';
import makeThrowError from '@heureca/shared/utils/apiErrors.utils';

import CreateRegistration from '../models/CreateRegistration.model';

const mapError = (error: ApiError) => {
  switch (error.status) {
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const createRegistration = (registration: CreateRegistration): Promise<void> => httpService
  .post('/companies/pre-sign-up', registration)
  .then((value) => value.data)
  .catch(throwError);

const exists = (routeName: string): Promise<boolean> => httpService
  .get(`/companies/search?routeName=${routeName}&isActive=false`)
  .then(() => true)
  .catch(() => false);

export default {
  createRegistration,
  exists,
};
