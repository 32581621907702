import CompanyType from '@heureca/signUp/models/CompanyType.model';
import SelectOption from '../models/SelectOption.model';

const mapCompanyTypeToSelect = (companyType: CompanyType): SelectOption => ({
  label: companyType.keyName,
  value: companyType.id,
});

export default {
  mapCompanyTypeToSelect,
};
