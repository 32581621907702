/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';

import IFormValues from '@heureca/shared/models/IFormValues.model';

import * as styles from './inputWebsite.module.scss';

interface Props {
  label: string;
  required: boolean;
  error?: FieldError;
  customErrorMessage?: string;
  name: Path<IFormValues>;
  validate?: (value: string) => boolean;
  placeholder?: string;
  value?: string;
  register?: UseFormRegister<IFormValues>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputWebsite = (props: Props) => {
  const {
    label,
    required,
    customErrorMessage,
    error,
    name,
    placeholder,
    register,
    validate,
    value,
    onChange,
  } = props;

  const [internalValue, setInternalValue] = useState(value);
  const registerProps = register(name, { required, validate, value: internalValue });

  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.currentTarget.value);
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <label htmlFor={label} className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      <div className={classNames(styles.inputWrapper, { [styles.error]: error })}>
        <div className={styles.addonBefore}>
          https://
        </div>
        <input
          {...registerProps}
          type="text"
          name={name}
          value={internalValue}
          placeholder={placeholder}
          className={styles.input}
          onChange={onChanged}
        />
        <div className={styles.addonAfter}>
          .myheureca.com
        </div>
      </div>
      <p className={classNames({ [styles.errorMessage]: !error })}>
        {customErrorMessage || "Le champ n'est pas valable"}
      </p>
    </div>
  );
};

export default InputWebsite;
