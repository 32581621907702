import ApiError from '@heureca/shared/models/ApiError.model';
import httpService from '@heureca/shared/services/http.service';
import makeThrowError from '@heureca/shared/utils/apiErrors.utils';

import CompanyType from '../models/CompanyType.model';

const mapError = (error: ApiError) => {
  switch (error.status) {
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const fetchCompanyTypes = (): Promise<CompanyType[]> => httpService
  .get('/company-types')
  .then((value) => value.data)
  .catch(throwError);

export default {
  fetchCompanyTypes,
};
