import { useRef } from 'react';

const usePrevious = <T>(value: T) => {
  const currentValue = useRef(value);
  const previousValue = useRef(currentValue.current);

  if (value !== previousValue.current) {
    previousValue.current = currentValue.current;
    currentValue.current = value;
  }

  return previousValue.current;
};

export default usePrevious;
